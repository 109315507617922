<template>
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div
              data-v-5cb60a6f=""
              class="card"
          ><!----><!---->
            <div
                data-v-5cb60a6f=""
                class="m-2"
            >
              <div
                  data-v-5cb60a6f=""
                  class="row"
              >
                <div
                    id="__BVID__826"
                    role="group"
                    class="form-row form-group col-4"
                    data-v-5cff0742=""
                >
<!--                  <div class="col form-group">-->
<!--                    <label>Region</label>-->
<!--                    <select-->
<!--                        id=""-->
<!--                        v-model="region"-->
<!--                        class="form-control col-11"-->
<!--                        name=""-->
<!--                        @change = "this.filterCluster"-->
<!--                    >-->
<!--                      <option-->
<!--                          v-for="(item,index) in getterListRegion"-->
<!--                          :value="item.id"-->
<!--                      >-->
<!--                        {{ item.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
                  <router-link
                      class="btn btn-primary"
                      :to="{ name: 'apps-size-add'}"
                  >
                    Add Size
                  </router-link>
                </div>
<!--                <div class="col-3 mt-2">-->
<!--                  <router-link-->
<!--                      class="btn btn-primary"-->
<!--                      :to="{ name: 'apps-size-add'}"-->
<!--                  >-->
<!--                    Add Cluster-->
<!--                  </router-link>-->
<!--                </div>-->
              </div>
            </div>
            <div
                data-v-5cb60a6f=""
                class="position-relative table-responsive"
            >
              <table
                  id="__BVID__1070"
                  role="table"
                  aria-busy="false"
                  aria-colcount="7"
                  class="table b-table"
              ><!---->
                <!---->
                <thead
                    role="rowgroup"
                    class=""
                ><!---->
                <tr
                    role="row"
                    class=""
                >
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="1"
                      aria-sort="descending"
                      class=""
                  >
                    <div>#</div>
                  </th><th
                    role="columnheader"
                    scope="col"
                    tabindex="0"
                    aria-colindex="3"
                    aria-sort="none"
                    class=""
                >
                  <div>Name</div>
                </th><th
                    role="columnheader"
                    scope="col"
                    tabindex="0"
                    aria-colindex="4"
                    aria-sort="none"
                    class=""
                >
                  <div>Region</div>
                </th>
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                  >
                    <div>CPU</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                  >
                    <div>RAM</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                  >
                    <div>Disk</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="4"
                      aria-sort="none"
                      class=""
                  >
                    <div>MariaDB Disk</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      tabindex="0"
                      aria-colindex="6"
                      aria-sort="none"
                      class=""
                  >
                    <div>Created at</div>
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody role="rowgroup"><!---->
                <tr
                    v-for="(item,index) in getterListSize"
                    id="__BVID__1070__row_5036"
                    role="row"
                    data-pk="5036"
                    class=""
                >
                  <td
                      aria-colindex="1"
                      role="cell"
                      class=""
                  ><a
                      data-v-5cb60a6f=""
                      class="font-weight-bold"
                      target="_self"
                  > {{ index + 1 }} </a></td>
                  <td
                      aria-colindex="3"
                      role="cell"
                      class=""
                  >
                    <div
                        data-v-5cb60a6f=""
                        class="media"
                    >
                      <div
                          data-v-5cb60a6f=""
                          class="media-body"
                      ><span
                          data-v-5cb60a6f=""
                          class="font-weight-bold d-block text-nowrap"
                      > {{ item.name }} </span></div>
                    </div>
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ item.region != null ? item.region.name : 'NO AVAILABLE' }}
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ item.cpu}}
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ item.disk}}
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ item.mariadb_disk}}
                  </td>
                  <td
                      aria-colindex="4"
                      role="cell"
                      class=""
                  >
                    {{ item.ram}}
                  </td>
                  <td
                      aria-colindex="6"
                      role="cell"
                      class=""
                  >
                    {{ getDate(new Date(item.created_at )) }}
                  </td>
                  <td>
                    <button class="btn btn-danger" @click="handleDeleteSize(item.id)">DELETE</button>
                    <router-link
                        class="btn btn-warning ml-2"
                        :to="'/apps/size/edit/' + item.id"
                    >
                      Edit
                    </router-link>
                  </td>
                </tr>
                </tbody><!----></table>
            </div>
            <div
                data-v-5cb60a6f=""
                class="mx-2 mb-2"
            /><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.esm.browser'

export default {
  name: 'ClusterList',
  components: {},
  data() {
    return {
      listRegion:[],
      region : null
    }
  },
  created() {
    this.$store.commit('SHOW_LOADING', true)
    this.getListSize({ regionID: null }).then(res => {
      this.$store.commit('SHOW_LOADING', false)
      console.log('RES getListSize', res)
    }).catch(e=>{
      this.$store.commit('SHOW_LOADING', false)
    })

    // this.getListWPRegion({ typeGet: 'all' }).then(res => {
    //
    // }).catch(e=>{
    //   console.log(e)
    // })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListSize']),
  },
  methods: {
    ...mapActions(['getListSize','deleteSize']),
    getDate(date) {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    },
    isActive(flag) {
      if (flag) {
        return '<button class="btn btn-success">Hoạt động</button>'
      }
      return '<button class="btn btn-danger">Ngừng hoạt động</button>'
    },
    filterCluster(){
      this.getListCluster({ regionID: this.region }).then(res => {
        // this.$store.commit('SHOW_LOADING', false)
        // console.log('RES filterCluster', res)
      }).catch(e=>{
        // this.$store.commit('SHOW_LOADING', false)
      })
    },
    getPathFile(file){
      // alert('file' + file)
      return process.env.VUE_APP_API_SERVICE + atob(file)
    },
    handleDeleteSize(id){
      if (!confirm("Are you sure!")) return false
      this.deleteSize(id)
    }
  },
}
</script>

<style scoped>

</style>
